<template>
    <Head title="Vendors" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendors</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="vendor-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-3">
            <label for="search-vendors" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-vendors" id="search-vendors" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('vendors.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="w-full my-4">
        <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
            <div v-if="vendors.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-gray-700">Showing {{ vendors.from }} - {{ vendors.to }} out of {{ vendors.total }} Vendors</div>

            <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
                <inertia-link :href="$route('vendors.create')" class="btn btn-orange mr-2">
                     New
                </inertia-link>

                <loading-button :loading="saving" class="btn btn-gray" @click="exportData" :disabled="!vendors.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>

        <div>
            <div class="overflow-x-auto">
                <transition
                        enter-active-class="transition-height ease-in-out duration-500"
                        enter-from-class="h-0"
                        enter-to-class="h-full"
                        leave-active-class="transition-height ease-in-out duration-500"
                        leave-from class="h-full"
                        leave-to-class="h-0">
                    <div v-show="vendors.data.length && selectedVendorIds.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                        <span v-if="selectedVendorIds.length === 1" class="mr-8">{{  `${selectedVendorIds.length} item selected` }}</span>
                        <span v-else class="mr-8">{{  `${selectedVendorIds.length} items selected` }}</span>

                        <div class="flex space-x-3">
                            <loading-button :loading="deleting" @click="deleteSelectedVendors" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyDeletedSelected">Delete</loading-button>
                        </div>
                    </div>
                </transition>
                <table class="table table-condensed" ref="table">
                    <thead>
                        <tr>
                            <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <input type="checkbox" :disabled="!vendors.data.length" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :checked="vendors.data.length && (indeterminate || selectedVendorIds.length === vendors.data.length)" :indeterminate="indeterminate" @change="selectedVendorIds = $event.target.checked ? vendors.data.map((vendor) => vendor.id) : []" />
                            </th>
                            <sortable-table-header field="name" :filters="filters" route-name="vendors.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">Name</sortable-table-header>
                            <th v-show="form.selected_headers.includes('Locations')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Locations</th>
                            <th v-show="form.selected_headers.includes('Contracts')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Contracts</th>
                            <th v-show="form.selected_headers.includes('Non Terminated Services')" scope="col" class="px-3 py-3.5 text-right font-semibold text-gray-900">Non-Terminated Services</th>
                            <sortable-table-header v-show="form.selected_headers.includes('General Email Address')" field="general_email_address" :filters="filters" route-name="vendors.index" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900">General Email Address</sortable-table-header>
                            <sortable-table-header v-show="form.selected_headers.includes('Created Date')" field="created_at" :filters="filters" route-name="vendors.index" scope="col" class="w-36 px-3 py-3.5 text-left font-semibold text-gray-900">Created Date</sortable-table-header>
                            <th scope="col" class="w-36 px-3 py-3.5 text-right font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr :class="{'table-row-deleted':vendor.deleted_at, 'bg-gray-50': selectedVendorIds.includes(vendor.id)}" v-for="vendor in vendors.data" :key="vendor.id">
                            <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                                <div v-if="selectedVendorIds.includes(vendor.id)" class="absolute inset-y-0 left-0 w-0.5 bg-d-orange-600"></div>
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-d-orange-500 focus:ring-d-orange-500 sm:left-6" :value="vendor.id" v-model="selectedVendorIds" />
                            </td>
                            <td>
                                <inertia-link data-cy="vendors.index.show-link" v-if="!vendor.deleted_at" :href="$route('vendors.show', [vendor.id])" class="link">
                                    {{ vendor.name }}
                                </inertia-link>

                                <template v-else>{{ vendor.name }}</template>
                            </td>
                            <td v-show="form.selected_headers.includes('Locations')" class="text-right">
                                <inertia-link :href="$route('locations.index', {search: vendor.name})" class="link">
                                    {{ getLocationsCount(vendor.id) }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Contracts')" class="text-right">
                                <inertia-link :href="$route('vendor-contracts.index', {search: vendor.name})" class="link">
                                    {{ getContractsCount(vendor.id) }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('Non Terminated Services')" class="text-right">
                                <inertia-link :href="$route('services.index', {search: vendor.name})" class="link">
                                    {{ getNonTerminatedServicesCount(vendor.id) }}
                                </inertia-link>
                            </td>
                            <td v-show="form.selected_headers.includes('General Email Address')">
                                <copy-to-clipboard-button :value="vendor.general_email_address" :class="{italic:vendor.deleted_at}">{{ vendor.general_email_address }}</copy-to-clipboard-button>
                            </td>
                            <td v-show="form.selected_headers.includes('Created Date')">
                                {{ $filters.format_date(vendor.created_at) }}
                            </td>
                            <td class="text-right">
                                <span class="inline-block">
                                    <inertia-link :href="$route('vendors.show', [vendor.id])" class="link mr-3" title="View This Vendor">
                                        View
                                    </inertia-link>
                                </span>
                                <span class="inline-block">
                                    <inertia-link v-if="!vendor.deleted_at" :href="$route('vendors.edit', [vendor.id])" class="link" title="Edit This Vendor">
                                        Edit
                                    </inertia-link>

                                    <template v-else>Deleted</template>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        <pagination :links="vendors.links" />
    </div>

        <div v-if="!vendors.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
            <icon name="truck" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
            <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendors Found</span>
        </div>

        <slide-over ref="searchFilterSlideOver">
            <template #header="{close}">
                <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                        <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                        <div class="ml-3 flex h-7 items-center">
                            <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </template>

            <template #body>
                <form @submit.prevent id="vendor-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">

                    <div class="mt-1 col-span-6 sm:col-span-2 md:col-span-1">
                        <date-input label="After Created Date" v-model="form.after_created_date"></date-input>
                    </div>

                    <div class="mt-1 col-span-6 sm:col-span-2 md:col-span-1">
                        <date-input label="And Before Created Date" v-model="form.before_created_date"></date-input>
                    </div>
                </form>
            </template>
        </slide-over>
    </div>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { pickBy, throttle } from 'lodash-es';
    import ToggleSwitchInput from "../../Shared/ToggleSwitchInput.vue";
    import DateInput from "../../Shared/DateInput.vue";
    import SlideOver from "../../Shared/SlideOver.vue";
    import ColumnSelector from "../../Shared/ColumnSelector.vue";


    export default {
        components: {
            CopyToClipboardButton,
            Icon,
            LoadingButton,
            SelectInput,
            Pagination,
            SortableTableHeader,
            ToggleSwitchInput,
            DateInput,
            SlideOver,
            Head,
            ColumnSelector,
        },

        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            vendors: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

            nonTerminatedServicesCount: {
                type: Object,
                required: true
            },

            locationsCount: {
                type: Object,
                required: true
            },

            contractsCount: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    after_created_date: this.filters.after_created_date,
                    before_created_date: this.filters.before_created_date,
                    selected_headers: this.filters.selected_headers,
                },
                saving: false,
                deleting: false,
                selectedVendorIds: [],
                headers: [],
                excludedHeaders: ['Actions', 'Name'],
                mounted: false,
                filtersInUse: 0,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.vendors.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },
            deleteSelectedVendors() {
                this.deleting = true;
                this.$inertia.post(this.$route('vendors.delete-selected'), {
                    vendor_ids: this.selectedVendorIds,
                }, {
                    onSuccess: () => this.selectedVendorIds = [],
                    onFinish: () => this.deleting = false,
                });
            },

            getNonTerminatedServicesCount(vendorId) {
                return this.nonTerminatedServicesCount[vendorId] ?? 0;
            },

            getLocationsCount(vendorId) {
                return this.locationsCount[vendorId] ?? 0;
            },

            getContractsCount(vendorId) {
                return this.contractsCount[vendorId] ?? 0;
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                this.headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        let query = pickBy(this.form);
                        query = Object.keys(query).length ? query : {remember: 'forget'};
                        if(this.mounted) {
                            this.$inertia.get(this.$route('vendors.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            indeterminate() {
                return this.selectedVendorIds.length > 0 && this.selectedVendorIds.length < this.vendors.length;
            },

            onlyDeletedSelected() {
                return this.selectedVendorIds.every((id) => {
                    return !this.vendors.data.find((vendor) => vendor.id === id).deleted_at && this.getNonTerminatedServicesCount(id) === 0;
                });
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.after_created_date,
                    form.before_created_date,
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>