<template>
    <Head :title="`Compactor Monitor - ${compactorMonitor.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
	<div class="lg:sticky lg:top-0 lg:bg-white lg:z-20">
	    <CompactorMonitorHeader :compactor-monitor="compactorMonitor" />
		<SubNav :compactor-monitor="compactorMonitor" current-tab="overview"/>
	</div>
    <div class="max-w-7xl mx-auto grid grid-cols-1 gap-6 my-3 3xl:max-w-screen-2xl lg:grid-flow-col-dense lg:grid-cols-2 4xl:max-w-none">
        <div class="col-span-2 mx-2 md:mx-3 lg:mx-4">
            <section class="mb-2" aria-labelledby="record-information-title">
                <div class="bg-white shadow sm:rounded-lg mx-auto py-6 px-5">
	                <div class="grid grid-cols-1 lg:grid-cols-6 mr-1.5 divide-y md:divide-y-0 lg:divide-x px-4 md:px-6 lg:px-0">
	                    <div class="col-span-5 lg:col-span-3">
		                    <dd class="grid grid-cols-2 mt-1 lg:px-8">
			                    <div class="col-span-2 md:col-span-1 py-1 mb-5 mr-2">
				                    <div class="text-lg lg:text-xl font-medium text-gray-500 ">
					                    <template
						                    v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'">
						                    Scheduled Pickup
					                    </template>

					                    <template
						                    v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'">
						                    Requested Pickup
					                    </template>

					                    <template v-else>
						                    Forecasted Pickup
					                    </template>
				                    </div>
				                    <dd class="mt-1 leading-9 text-lg lg:text-xl bg-d-cyan-100 max-w-fit rounded py-1 px-2 font-semibold text-gray-900 mb-3" :content="predictedPickupHelpText" v-tippy>
					                    <template
						                    v-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'scheduled'"
						                    class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold text-green-500">
						                    {{ upcomingPickupDateFormatted }}
					                    </template>

					                    <template
						                    v-else-if="monitorSummary.upcoming_pickup_date && monitorSummary.upcoming_pickup_type === 'requested'"
						                    class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold text-yellow-500">
						                    {{ upcomingPickupDateFormatted }}
					                    </template>

					                    <template v-else-if="predictedPickupDateFormatted"
					                              class="mt-1 bg-d-cyan-100 text-xl font-semibold" >
						                    <div class="flex flex-col">
							                    {{ predictedPickupDateFormatted }}
						                    </div>
					                    </template>

					                    <template v-else class="mt-1 bg-d-cyan-100 text-xl leading-9 font-semibold">
						                    Estimate unavailable
					                    </template>
				                    </dd>
			                    </div>
								<div class="col-span-2 md:col-span-1 md:py-1 md:mb-5">
				                    <div class="text-lg lg:text-xl font-medium text-gray-500">
					                    Current Fullness
				                    </div>
				                    <dd class="mt-2 leading-9 text-lg lg:text-xl max-w-fit rounded  font-semibold text-gray-900 mb-3">
									     <span class="mt-1 bg-d-cyan-100 rounded py-1 px-2 font-semibold">
										     {{ compactorMonitor.monitorConfiguration.percentage_full }}%
									     </span>
									     <span class="text-sm ml-1 font-normal">({{ fullnessInTons }} tons <i>est.</i>)</span>
					                </dd>
			                    </div>
			                    <div v-if="compactorMonitor.monitorConfiguration.percentage_full && compactorMonitor.monitorConfiguration.tonnage_capacity" class="col-span-2">
			                        <div class="relative h-10 w-full bg-gray-200 rounded flex items-center mt-11">
					                    <div :style="`left:${compactorMonitor.monitorConfiguration.target_percentage_full}%`" class="w-1 h-full bg-d-orange-500 absolute items-center">
						                    <div class="w-px text-xs h-3 absolute bottom-12 right-10 transform -translate-y-1/2" content="If Predictive Pickup Scheduling is enabled, AutoPilot will aim for pickups to occur at the target" v-tippy>
							                    <span class="border-2 p-1 whitespace-nowrap">Target {{ compactorMonitor.monitorConfiguration.target_percentage_full }}%</span>
						                    </div>
					                    </div>
					                    <!-- tick for 25% -->
					                    <div style="left: 25%" class="w-px h-full bg-d-gray-800 absolute">
						                    <div class="w-px h-3 absolute text-xs text-d-gray-700 top-12 right-3 transform -translate-y-1/2">25%</div>
					                    </div>
					                    <!-- tick for 50% -->
					                    <div style="left: 50%" class="w-px h-full bg-d-gray-800 absolute">
						                    <div class="w-px h-3 absolute text-xs text-d-gray-700 top-12 right-3 transform -translate-y-1/2">50%</div>
					                    </div>
					                    <!-- tick for 75% -->
					                    <div style="left: 75%" class="w-px h-full bg-d-gray-800 absolute">
						                    <div class="w-px h-3 absolute text-xs top-12 text-d-gray-700 right-3 transform -translate-y-1/2">75%</div>
					                    </div>
					                    <div :style="`width:${initialWidth}%`" class="h-full bg-d-cyan-500 rounded transition-all duration-5000 ease-in"/>
				                    </div>
			                    </div>
			                    <template v-else>
				                    N/A
			                    </template>
		                    </dd>
		                    <div class="block lg:hidden border-b mx-6" />
                        </div>
		                <div class="lg:col-span-3 mt-8 md:pt-8 lg:pt-0 lg:mt-0 lg:px-8 ml-0.5 grid md:grid-cols-2 divide-y md:divide-y-0 md:divide-x">
			                <div class="mb-5 md:mb-0">
				                <div class="pt-5 text-md col-span-2 lg:col-span-1">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Predictive Pickup Scheduling:
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-900 mb-3"
					                    :class="compactorMonitor.monitorConfiguration.create_work_orders ? 'text-green-600' : 'text-red-600'">
						                {{ compactorMonitor.monitorConfiguration.create_work_orders ? 'Enabled' : 'Disabled' }}
					                </dd>
				                </div>
				                <div class="text-md pt-5">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Last Pickup
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-500 mb-3">
						                <div class="grid">
							                <inertia-link v-if="lastPickupWorkOrder" :href="$route('work-orders.edit', lastPickupWorkOrder.id)" class="font-semibold link">
								                {{ formattedLastPickup }}
							                </inertia-link>
							                <span v-if="daysSinceLastPickup" class="text-xs">
								                ({{ daysSinceLastPickup }} days ago)
							                </span>
						                </div>
						                <template >
							                No pickups recorded
						                </template>
					                </dd>
				                </div>
				                <div class="text-md pt-5">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Average Days Between Hauls
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-500 mb-3">
						                {{ compactorMonitor.monitorConfiguration.average_actual_days_between_pickups }}
					                </dd>
				                </div>
				                    <div class="pt-5 col-span-2 lg:col-span-1">
			                    </div>
			                </div>
			                <div class="md:pl-10">
				                <div class="pt-5 text-md col-span-2 lg:col-span-1">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Preventative Maintenance:
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-900 mb-3"
					                    :class="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'text-green-600' : 'text-red-600'">
						                {{ compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'Enabled' : 'Disabled' }}
					                </dd>
				                </div>
				                <div class="text-md pt-5">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Last Cycle Detected
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-500 mb-3 grid">
						                {{ format(parseISO(this.lastRan), 'iii, MMM do h:mm a') }}
						                <span class="inline text-xs">({{ formattedLastRanDays }})</span>
					                </dd>
				                </div>
				                <div class="text-md pt-5">
					                <dt class="leading-5 font-medium text-gray-500 truncate">
						                Average Cycles Per Day
					                </dt>
					                <dd class="mt-1 leading-9 text-gray-500 mb-3">
						                {{ averageCyclesPerDay}}
					                </dd>
		                        </div>
		                    </div>
                        </div>
	                </div>
                </div>
	            <div class="bg-white shadow sm:rounded-lg mx-auto mt-5 py-6">
		            <dl class="">
			            <div class="pl-4 pr-3 mb-6">
				            <h2 id="record-information-title" class="text-2xl leading-6 font-medium text-gray-800 flex">
					                <span>Lifetime Pioneer Impact</span>
					                <tooltip-icon class="inline" :tooltip="`Pioneer's impact since AutoPilot began scheduling pickups on
									${$filters.format_date(compactorMonitor.monitorConfiguration?.pickup_management_start_date)}`"/>
				            </h2>
			            </div>
			            <div id="chart-container" class="grid md:grid-cols-2 lg:grid-cols-4 divide-x flex print:border-none rounded-lg ">
				            <div class="px-4 md:px-6 print:px-0 print:my-0 overflow-y-hidden ">
					            <compactor-monitor-reporting-dashboard-bar-chart
						            :series="getGrossSpendSeries()"
						            :metric-data="reportData.delta.gross_spend_amount_sum_money?.amount"
						            :delta-percentage="$filters.roundWithPrecision(reportData.delta?.gross_spend_amount_sum_pct, 0)"
						            trend-prefix="$"
						            trend-title="Hauling Cost"
						            :is-inverse-trend="true"
						            :show-charts="false"
						            container-name="gross-spend-chart"
						            class="col-span-1 print:mx-0 print:w-full "
						            ref="gross-spend-chart"/>
				            </div>
				            <div class="px-4 md:px-6 print:px-0 print:my-0">
					            <compactor-monitor-reporting-dashboard-bar-chart
						            :series="getHaulsRecordedSeries()"
						            :metric-data="$filters.roundWithPrecision(reportData.delta?.hauls_recorded_sum, 2)"
						            :delta-percentage="$filters.roundWithPrecision(reportData.delta?.hauls_recorded_sum_pct, 0)"
						            container-name="hauls-chart"
						            trend-suffix="hauls"
						            trend-title="Pickups"
						            :is-inverse-trend="true"
						            :show-charts="false"
						            class="col-span-1 print:mx-0 print:w-full"
						            ref="gross-spend-chart"/>
				            </div>
				            <div class="px-4 md:px-6 print:px-0 print:my-0">
					            <compactor-monitor-reporting-dashboard-bar-chart
						            :series="getGHGEmissionsSeries()"
						            :metric-data="$filters.roundWithPrecision(reportData.delta?.ghg_emissions, 2)"
						            :delta-percentage="$filters.roundWithPrecision(reportData.delta?.ghg_emissions_pct, 0)"
						            trend-title="GHG Emissions"
						            trend-suffix="pounds"
						            :is-inverse-trend="true"
						            :show-charts="false"
						            container-name="ghg-emissions-chart"
						            class=" col-span-1 print:mx-0 "
						            ref="gross-spend-chart"/>
				            </div>
				            <div class="px-4 md:px-6 print:px-0 print:my-0">
					            <compactor-monitor-reporting-dashboard-bar-chart
						            :series="getPickupWeightSeries()"
						            :metric-data="$filters.roundWithPrecision(reportData.delta?.avg_tons_per_haul, 2)"
						            :delta-percentage="$filters.roundWithPrecision(reportData.delta?.avg_tons_per_haul_pct, 0)"
						            trend-title="Average Pickup Weight"
						            metric-type="weight"
						            trend-suffix="ton"
						            :show-charts="false"
						            container-name="pickup-weight-chart"
						            class="col-span-1 print:mx-0"
						            ref="gross-spend-chart"/>
				            </div>
				            <div class="text-xxs hidden print:block col-span-4">BEFORE PIONEER values are estimated based upon the average baseline values of {{ compactorMonitor.monitorConfiguration?.pre_monitor_avg_hauls_per_month ?? preMonitorValues.avg_hauls_per_month }} hauls per month and
					            {{ compactorMonitor.monitorConfiguration?.pre_monitor_avg_tons_per_month ? compactorMonitor.monitorConfiguration?.pre_monitor_avg_tons_per_month : preMonitorValues.avg_tons_per_haul }} tons per haul.
				            </div>
			            </div>
		            </dl>
	            </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from "../../Shared/LoadingButton.vue";
    import CompactorMonitorFullnessColumnChart from '@/Shared/Reports/CompactorMonitorFullnessColumnChart.vue';
    import CompactorMonitorRunsHeatmap from '@/Shared/Reports/CompactorMonitorRunsHeatmap.vue';
    import ParticleVitalsAreaChart from "../../Shared/Reports/ParticleVitalsAreaChart.vue";
    import {differenceInCalendarDays, format, parseISO, fromUnixTime, differenceInDays, differenceInHours} from 'date-fns';
    import { debounce } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import axios from "axios";
    import CompactorMonitorHeader from "./CompactorMonitorHeader.vue";
    import SubNav from "./SubNav.vue";
    import CompactorMonitorReportingDashboardBarChart from "../../Shared/Reports/CompactorMonitors/Dashboard/CompactorMonitorReportingDashboardBarChart.vue";
    import TooltipIcon from "../../Shared/TooltipIcon.vue";

export default {
    components: {
	    TooltipIcon,
	    CompactorMonitorReportingDashboardBarChart,
	    SubNav,
	    CompactorMonitorHeader,
        ParticleVitalsAreaChart,
        LoadingButton,
        Icon,
        DateInput,
        CompactorMonitorFullnessColumnChart,
        CompactorMonitorRunsHeatmap,
        Head,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitor: {
            type: Object,
            required: true
        },

		monitorSummary: {
			type: Object,
			required: true
		},

	    lastPickupWorkOrder: {
		    required: false
	    },

	    daysSinceLastPickup: {
            required: false
	    },

	    lastRan: {
		    required: false
	    },

	    reportData: {
		    required: false
	    },

	    preMonitorValues: {
		    required: false
	    },
    },

    data() {
        return {
            form: {
                begin_date: null,
                end_date: format(Date.now(), 'yyyy-MM-dd'),
            },
            mounted: false,
	        initialWidth: 0,
            deviceInfoAndVitalsRefreshed: 0,
	        statuses: {
		        'online': 'animate-pulse text-green-400 bg-green-400/20',
		        'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
		        'inactive': 'text-gray-400 bg-gray-400/20',
	        },
        }
    },

    mounted() {
	    this.increaseWidth();
        Echo.join(`compactor_monitors.${this.compactorMonitor.id}`)
            .listen('.App\\Events\\CompactorMonitor\\SignalCompactorMonitorJobStarted', (e) => {
                this.signaling = true;
            })
            .listen('.App\\Events\\CompactorMonitor\\SignalCompactorMonitorJobFinished', (e) => {
                this.signaling = false;
                this.$toast.success('Finished signaling device.');
            })

        this.form.begin_date = this.threeMonthsAgo();

        this.mounted = true;
    },

    unmounted() {
        Echo.leave(`compactor_monitors.${this.compactorMonitor.id}`);
    },

    methods: {
	    format,
	    parseISO,
        threeMonthsAgo() {
            const now = new Date();
            // Adjust the following to use the start of the month:
            const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate()).setDate(1);

            return format(threeMonthsAgo, 'yyyy-MM-dd');
        },

	    increaseWidth() {
		    setTimeout(() => {
			    /* Assuming 100 is the full width, replace it as per your need */
			    this.initialWidth = this.compactorMonitor.monitorConfiguration.percentage_full; // will appear as w-100, w-50 etc.
		    }, 500); // delay of 500ms, can be adjusted
	    },

	    getGrossSpendSeries() {
		    return [
			    {
				    name: 'Before Pioneer',
				    value: this.reportData.withoutMonitor?.gross_spend_amount_sum_money ? parseInt(this.reportData.withoutMonitor.gross_spend_amount_sum_money.amount) / 100 : null
			    },
			    {
				    name: 'With Pioneer',
				    value: this.reportData.data?.gross_spend_amount_sum_money ? parseInt(this.reportData.data.gross_spend_amount_sum_money.amount) / 100 : null
			    }
		    ];
	    },

	    getHaulsRecordedSeries() {
		    return [
			    {
				    name: 'Before Pioneer',
				    value: this.reportData.withoutMonitor?.hauls_recorded_sum
			    },
			    {
				    name: 'With Pioneer',
				    value: this.reportData.data?.hauls_recorded_sum
			    }
		    ];
	    },

	    getGHGEmissionsSeries() {
		    return [
			    {
				    name: 'Before Pioneer',
				    value: this.reportData.withoutMonitor?.ghg_emissions
			    },
			    {
				    name: 'With Pioneer',
				    value: this.reportData.data?.ghg_emissions
			    }
		    ];
	    },

	    getPickupWeightSeries() {
		    return [
			    {
				    name: 'Before Pioneer',
				    value: this.reportData.withoutMonitor?.avg_tons_per_haul_avg
			    },
			    {
				    name: 'With Pioneer',
				    value: this.reportData.data?.avg_tons_per_haul_avg
			    }
		    ];
	    },
    },

    computed: {
        predictedPickupDateFormatted() {
            if (!this.compactorMonitor.monitorConfiguration.projected_pickup_date) {
                return '';
            }

            let predictedPickupDate = parseISO(this.compactorMonitor.monitorConfiguration.projected_pickup_date);
			let daysOut = differenceInCalendarDays(predictedPickupDate, new Date());
	        let dayText = daysOut + ' days';

	        if (daysOut === 0) {
		        dayText = 'Today';
	        } else if (daysOut === -1) {
		        dayText = 'Yesterday';
	        } else if (daysOut === 1) {
		        dayText = 'Tomorrow';
	        }

            return `${format(predictedPickupDate, 'iii, MMM do')} (${dayText})`;
        },

	    predictedPickupHelpText() {
			if (this.monitorSummary.projected_pickup_type === 'max_days' && this.monitorSummary.projected_pickup_date) {
				return 'Max days expected to be met prior to compactor reaching target fullness.';
			} else if (this.monitorSummary.projected_pickup_type === 'forecast' && this.monitorSummary.projected_pickup_date) {
				return 'The forecasted date of the next pickup.';
			}
	    },

        upcomingPickupDateFormatted() {
            if (!this.monitorSummary.upcoming_pickup_date) {
                return '';
            }

	        let daysOut = differenceInCalendarDays(parseISO(this.monitorSummary.upcoming_pickup_date), new Date());
			let dayText = daysOut + ' days';

	        if (daysOut === 0) {
		        dayText = 'Today';
	        } else if (daysOut === -1) {
		        dayText = 'Yesterday';
	        } else if (daysOut === 1) {
		        dayText = 'Tomorrow';
	        }

            return `${format(parseISO(this.monitorSummary.upcoming_pickup_date), 'iii, MMM do')} (${dayText})`;
        },

	    formattedLastPickup() {
		    if (!this.compactorMonitor.monitorConfiguration.last_picked_up && !this.lastPickupWorkOrder?.occurred_at) {
			    return '';
		    }

		    let lastPickedUp = parseISO(this.lastPickupWorkOrder.occurred_at ?? this.compactorMonitor.monitorConfiguration.last_picked_up);
		    return `${format(lastPickedUp, 'iii, MMM do')}`;
	    },

	    fullnessInTons() {
			return ((this.compactorMonitor.monitorConfiguration.percentage_full / 100) * this.compactorMonitor.monitorConfiguration.tonnage_capacity).toFixed(1);
	    },

	    formattedLastRanDays() {
		    if (!this.lastRan) {
			    return '';
		    }
		    let lastRan = parseISO(this.lastRan);
		    let hoursOut = differenceInHours(parseISO(this.lastRan), new Date());
		    let dayText = Math.abs(hoursOut) + ' hours ago';
			if (hoursOut < 23) {
				dayText = Math.round(Math.abs(hoursOut / 24)) + ' days ago';
			}
		    else if (hoursOut === 0) {
			    dayText = 'Today';
		    } else if (hoursOut === -1) {
			    dayText = 'Yesterday';
		    } else if (hoursOut === 1) {
			    dayText = 'Tomorrow';
		    }

		    // let lastRan = parseISO(this.lastRan);
		    return dayText;
	    },

	    averageCyclesPerDay() {
			let daysRun =  this.compactorMonitor.monitorConfiguration.runtime_since_last_pickup / differenceInDays(new Date(), parseISO(this.compactorMonitor.monitorConfiguration.last_picked_up));
			return Math.round(daysRun / this.compactorMonitor.monitorConfiguration.average_time_per_cycle);
	    }
    },
}
</script>