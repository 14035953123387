<template>
    <Head title="Compactor Monitors" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Compactor Monitors</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="compactor-monitor-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-3">
            <label for="search-compactor-monitors" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-compactor-monitors" id="search-compactor-monitors" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
                    <span class="inline-flex px-3 py-2">
                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                        <span class="ml-2">Filters</span>
                    </span>

                    <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
                        {{ filtersInUse }}
                    </span>
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('compactor-monitors.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="compactorMonitors.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ compactorMonitors.from }} - {{ compactorMonitors.to }} out of {{ compactorMonitors.total }} Compactor Monitors</div>

        <div class="flex col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end xl:col-span-1 xl:col-end-5">
            <loading-button class="btn btn-gray" @click="exportData" :disabled="!compactorMonitors.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
	                    <th scope="col" class="px-0 text-right text-sm font-semibold text-gray-900">
		                    <span v-tippy="'This column displays an icon if there are no runs detected within the past 2 business days.'">
			                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
                                <span class="sr-only">Needs Attention</span>
		                    </span>
	                    </th>
                        <sortable-table-header field="id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Monitor Id</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Device Service')" field="device_service_id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Device Service</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Monitored Service')" field="monitored_service_id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Monitored Service</sortable-table-header>
	                    <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Equipment Label</th>
                        <th v-show="form.selected_headers.includes('Location')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Location</th>
                        <th v-show="form.selected_headers.includes('Last Pickup')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Pickup</th>
                        <th v-show="form.selected_headers.includes('Predicted Pickup Date')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Predicted Pickup Date</th>
                        <th v-show="form.selected_headers.includes('Upcoming Pickup Date')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Upcoming Pickup Date</th>
                        <sortable-table-header v-show="form.selected_headers.includes('Percentage Full')" field="percentage_full" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" >Percentage Full</sortable-table-header>
	                    <th v-show="form.selected_headers.includes('Install Date')" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Install Date</th>
                        <th v-show="form.selected_headers.includes('Create Work Orders')" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Create Work Orders</th>
                        <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="compactorMonitor in compactorMonitors.data" :key="compactorMonitor.id">
	                    <td>
		                    <span v-if="needsAttention.includes(compactorMonitor.device_service_id)" class="px-0" v-tippy="'No runs within the past 2 business days!'">
		                        <icon  name="exclamation-triangle" class="text-rose-400 fill-current h-4 w-4" />
		                    </span>
	                    </td>
                        <td>
	                        <div class="flex items-center gap-x-2" :title="$filters.capitalize(compactorMonitor.connectivity_status)">
		                        <div :class="[statusClasses[compactorMonitor.connectivity_status], 'flex-none rounded-full p-1']">
			                        <div class="h-1.5 w-1.5 rounded-full bg-current" />
		                        </div>
	                            <inertia-link data-cy="compactor-monitors.index.show-link" :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="link" >
	                                {{ compactorMonitor.id }}
	                            </inertia-link>
	                        </div>
                        </td>
                        <td v-show="form.selected_headers.includes('Device Service')">
                            <div v-if="compactorMonitor.device_service_id" class="flex items-center gap-x-2">
                                <inertia-link :href="$route('services.show', compactorMonitor.device_service_id)" class="link">
                                    {{ compactorMonitor.device_service_id }}
                                </inertia-link>
                            </div>

                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Monitored Service')">
                            <div v-if="compactorMonitor.monitored_service_id" class="flex items-center gap-x-2">
                                <inertia-link :href="$route('services.show', compactorMonitor.monitored_service_id)" class="link">
                                    {{ compactorMonitor.monitored_service_id }}
                                </inertia-link>
                            </div>

                            <span v-else>
                                -
                            </span>
                        </td>
	                    <td v-show="form.selected_headers.includes('Equipment Label')">
		                    <span v-if="compactorMonitor.monitoredService?.label">
			                    {{ compactorMonitor.monitoredService.label }}
                            </span>
		                    <span v-else>
			                    -
                            </span>
	                    </td>
                        <td v-show="form.selected_headers.includes('Location')">
                            <inertia-link v-if="compactorMonitor.deviceService" :href="$route('locations.show', compactorMonitor.deviceService.location_id)" class="link">
                                {{ compactorMonitor.deviceService.location.name }}
                            </inertia-link>

                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Last Pickup')">
                            <span v-if="lastPickupWorkOrders[compactorMonitor.monitored_service_id]">
                                <inertia-link :href="$route('work-orders.edit', lastPickupWorkOrders[compactorMonitor.monitored_service_id].id)" class="link">
                                    {{ $filters.format_date_time(compactorMonitor.monitorConfiguration.last_picked_up) }}
                                </inertia-link>
                            </span>

                            <span v-else-if="compactorMonitor.monitorConfiguration?.last_picked_up">
                                {{ $filters.format_date_time(compactorMonitor.monitorConfiguration.last_picked_up) }}
                            </span>

                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Predicted Pickup Date')">
                            <span v-if="compactorMonitor.monitorConfiguration?.projected_pickup_date">
                                {{ $filters.format_date(compactorMonitor.monitorConfiguration?.projected_pickup_date) }}
                            </span>

                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Upcoming Pickup Date')">
                            <span v-if="latestUnsentWorkOrders[compactorMonitor.monitored_service_id]">
                                <inertia-link :href="$route('work-orders.edit', latestUnsentWorkOrders[compactorMonitor.monitored_service_id].id)" class="link">
                                    <span v-if="latestUnsentWorkOrders[compactorMonitor.monitored_service_id].scheduled_with_vendor_date">
                                        {{ latestUnsentWorkOrders[compactorMonitor.monitored_service_id].scheduled_with_vendor_date }}
                                    </span>

                                    <span v-else>
                                        {{ latestUnsentWorkOrders[compactorMonitor.monitored_service_id].requested_completion_date }}
                                    </span>
                                </inertia-link>
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Percentage Full')" class="text-right">
                            <span v-if="compactorMonitor.monitorConfiguration && compactorMonitor.monitorConfiguration.percentage_full !== null">
                                {{ compactorMonitor.monitorConfiguration.percentage_full }}%
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
	                    <td class="text-center" v-show="form.selected_headers.includes('Install Date')">
							<span v-if="!compactorMonitor.deviceService || !compactorMonitor.monitorConfiguration || !compactorMonitor.monitorConfiguration.first_run_detected_at">
								-
							</span>
		                    <span v-else>
								{{ $filters.format_date(compactorMonitor.monitorConfiguration.first_run_detected_at) }}
							</span>
	                    </td>
                        <td class="text-center" v-show="form.selected_headers.includes('Create Work Orders')">
                            <span v-if="!compactorMonitor.deviceService || !compactorMonitor.monitorConfiguration">
                                -
                            </span>
                            <span v-else :title="compactorMonitor.monitorConfiguration.create_work_orders ? 'Creates work orders' : 'Does not create work orders'">
                                <icon v-if="compactorMonitor.monitorConfiguration.create_work_orders" name="check" class="inline text-green-600 fill-current h-4 w-4" />
                                <icon v-else name="times" class="inline text-red-600 fill-current h-4 w-4" />
                            </span>
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="link mr-3" title="View This Record">
                                View
                            </inertia-link>

                            <inertia-link :href="$route('compactor-monitors.edit', compactorMonitor.id)" class="link" title="Edit This Record">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="compactorMonitors.links" />
    </div>

    <div v-if="!compactorMonitors.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="sensor" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Compactor Monitors Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="compactor-monitors-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
	            <div class="col-span-6 sm:col-span-1">
		            <select-input class="col-span-6 sm:col-span-1"  v-model="form.connectivity_status" label="Connectivity Status">
			            <option value="">Any</option>
			            <option v-for="status in statuses" :value="status">{{ $filters.capitalize(status) }}</option>
		            </select-input>
	            </div>
                <div class="col-span-6 sm:col-span-1">
                    <div class="col-span-1">
                        <label class="form-label w-1/2">Last Pickup Date</label>

                        <date-input v-model="form.last_pickup_begin_date" />

                        <span class="w-2/12 text-center">thru</span>

                        <date-input v-model="form.last_pickup_end_date" />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-1">
                    <select-input id="create-work-orders" name="create_work_orders" class="col-span-6 sm:col-span-1" v-model="form.create_work_orders" label="Create Work Orders">
                        <option value="">Any</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select-input>
                </div>
	            <div class="col-span-6 sm:col-span-1 inline">
		            <select-input id="stale monitors" name="needs_attention" class="col-span-6 sm:col-span-1" v-model="form.needs_attention" label="Needs Attention" icon="exclamation-triangle" icon-color="text-rose-400">
			            <option value="">All</option>
			            <option value="hide">Hide</option>
			            <option value="only">Only</option>
                    </select-input>
	            </div>
            </form>
        </template>
    </slide-over>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import Modal from '@/Shared/Modal.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue'
    export default {
        components: {
            DateInput,
            Icon,
            Pagination,
            SortableTableHeader,
            SelectInput,
            TextInput,
            Modal,
            LoadingButton,
            Head,
            ColumnSelector,
            SlideOver,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            compactorMonitors: {
                type: Object,
                required: true
            },

            lastPickupWorkOrders: {
                type: Object,
                required: true
            },

            latestUnsentWorkOrders: {
                type: Array,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },

	        statuses: {
		        type: Array,
		        required: true
	        },

	        needsAttention: {
		        type: Array,
		        required: true
	        }
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    per_page: this.filters.per_page,
                    create_work_orders: this.filters.create_work_orders ? this.filters.create_work_orders : '',
	                connectivity_status: this.filters.connectivity_status ? this.filters.connectivity_status : '',
                    last_pickup_begin_date: this.filters.last_pickup_begin_date,
                    last_pickup_end_date: this.filters.last_pickup_end_date,
	                needs_attention: this.filters.needs_attention ? this.filters.needs_attention : '',
                    selected_headers: this.filters.selected_headers,
                },
                headers: [],
                excludedHeaders: ['Needs Attention', 'Monitor Id', 'Actions'],
                saving: false,
                mounted: false,
                createWorkOrderComboBoxQuery: '',
                lastPickupComboBoxQuery: '',
                attachModalForm: {
                    compactor_monitor_id: null,
                    device_service_id: null,
                    monitored_service_id: null,
                },
                statusClasses: {
                    'online': 'animate-pulse text-green-400 bg-green-400/10',
                    'offline': 'animate-pulse text-rose-400 bg-rose-400/10',
                    'inactive': 'text-gray-400 bg-gray-400/10',
                },
                filtersInUse: 0
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = true;

                this.$inertia.post(this.$route('csv.compactor-monitors.index'), this.queryFilters, {
                    onFinish: () => { this.saving = false; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
	                .filter(th => !th.querySelector('img')) // Filter out svg headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if (this.mounted) {
                            this.$inertia.get(this.$route('compactor-monitors.index'), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
                    form.search,
                    form.create_work_orders,
                    form.last_pickup_begin_date,
                    form.last_pickup_end_date,
	                form.connectivity_status,
	                form.needs_attention
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        }
    }
</script>