<template>
    <aside class="flex flex-col gap-y-8 py-6 lg:col-span-3">
        <nav v-if="$can('manageTenantGeneralSettings')">
            <h3 class="mb-2 text-xs font-semibold text-d-accent-600 uppercase tracking-wider" id="projects-headline">
                Settings
            </h3>

            <inertia-link data-cy="settings.subnav.general" key="general" :href="$route('tenant-settings.edit')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('general')" :aria-current="'general' === currentTab">
                <span class="truncate">General</span>
            </inertia-link>

            <inertia-link v-if="$page.props.tenant_billing_type !== 'custom'" key="features" :href="$route('tenant-settings.billing-dashboard.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('billing-dashboard')" :aria-current="'billing-dashboard' === currentTab">
                <span class="truncate">Billing Dashboard</span>
            </inertia-link>

            <inertia-link key="employees" :href="$route('tenant-settings.users.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('employees')" :aria-current="'employees' === currentTab">
                <span class="truncate">Employees</span>
            </inertia-link>

            <inertia-link v-if="$can('accessIncidents')" key="incidents" :href="$route('tenant-settings.incident-types.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('incidents')" :aria-current="'incidents' === currentTab">
                <span class="truncate">Incidents</span>
            </inertia-link>

            <inertia-link key="webhooks" :href="$route('tenant-settings.webhooks.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('webhooks')" :aria-current="'webhooks' === currentTab">
                <span class="truncate">Webhooks</span>
            </inertia-link>
        </nav>

        <nav v-if="$can('manageTenantEmailSettings')">
            <h3 class="mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                Email
            </h3>

            <inertia-link key="email-templates" :href="$route('tenant-settings.email-templates.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('email-templates')" :aria-current="'bulk-imports' === currentTab">
                <span class="truncate">Email Templates</span>
            </inertia-link>

            <inertia-link key="external-email-settings" :href="$route('tenant-settings.external-email-settings.edit')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('external-email-settings')" :aria-current="'external-email-settings' === currentTab">
                <span class="truncate">External Email Settings</span>
            </inertia-link>
        </nav>

        <nav v-if="$can('manageTenantDataSettings')">
            <h3 class="mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                Data
            </h3>

            <inertia-link key="bulk-imports" :href="$route('tenant-settings.bulk-imports.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('bulk-imports')" :aria-current="'bulk-imports' === currentTab">
                <span class="truncate">Bulk Imports</span>
            </inertia-link>
        </nav>

        <nav v-if="$can('manageTenantAccountingSettings')">
            <h3 class="mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                Accounting
            </h3>

            <inertia-link key="accounting-integrations" :href="$route('tenant-settings.accounting-integrations.edit')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('accounting-integrations')" :aria-current="'accounting-integrations' === currentTab">
                <span class="truncate">Integration</span>
            </inertia-link>

            <inertia-link key="fiscal-periods" :href="$route('tenant-settings.fiscal-periods.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('fiscal-periods')" :aria-current="'fiscal-periods' === currentTab">
                <span class="truncate">Fiscal Periods</span>
            </inertia-link>

            <inertia-link v-if="$can('manageTenantSettings')" key="line-item-categories" :href="$route('tenant-settings.line-item-categories.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('line-item-categories')" :aria-current="'line-item-categories' === currentTab">
                <span class="truncate">Line Item Categories</span>
            </inertia-link>

            <inertia-link v-if="$can('accessRevenueManagement')" key="receipt-locations" :href="$route('tenant-settings.receipt-locations.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('receipt-locations')" :aria-current="'receipt-locations' === currentTab">
                <span class="truncate">Receipt Locations</span>
            </inertia-link>

            <inertia-link v-if="$can('accessRevenueManagement')" key="sales-taxes" :href="$route('tenant-settings.sales-taxes.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('sales-taxes')" :aria-current="'sales-taxes' === currentTab">
                <span class="truncate">Client Invoice Sales Taxes</span>
            </inertia-link>

            <inertia-link v-if="$can('manageTenantSettings')" key="vendor-sales-taxes" :href="$route('tenant-settings.vendor-sales-taxes.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('vendor-sales-taxes')" :aria-current="'vendor-sales-taxes' === currentTab">
                <span class="truncate">Vendor Invoice Sales Taxes</span>
            </inertia-link>

            <inertia-link v-if="$can('accessQuickbooksDesktopIntegration')" key="quickbooks-desktop" :href="$route('tenant-settings.quickbooks-desktop.accounting-exports.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('quickbooks-desktop')" :aria-current="'quickbooks-desktop' === currentTab">
                <span class="truncate">Quickbooks Desktop</span>
            </inertia-link>

            <inertia-link v-if="$can('accessQuickbooksOnlineIntegration')" key="quickbooks-online" :href="$route('tenant-settings.quickbooks-online.index')" class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium" :class="classBindings('quickbooks-online')" :aria-current="'quickbooks-online' === currentTab">
                <span class="truncate">Quickbooks Online</span>
            </inertia-link>
        </nav>
    </aside>
</template>

<script>
    import SelectInput from '@/Shared/SelectInput.vue';

    export default {
        components: {
            SelectInput
        },
        
        props: {
            currentTab: {
                type: String,
                required: true
            }
        },

        methods: {
            classBindings(linkName) {
                if (linkName === this.currentTab) {
                    return 'bg-white-100 border-d-orange-500 text-d-orange-500';
                } else {
                    return 'text-accent-900 border-transparent hover:border-d-accent-500 hover:bg-d-accent-100'
                }
            }
        },
    }
</script>