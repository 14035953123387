<template>
    <Head title="Create Client Contact" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('clients.index')" class="breadcrumb-link">Client Contacts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="py-6">
        <form id="create-client-contact-form" class="space-y-8" @submit.prevent="submitForm">
            <fieldset class="space-y-8 sm:space-y-5">
                <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Name, Company, Title, etc.</p>
                    </div>
                </legend>

                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <text-input id="first-name" v-model="form.first_name" class="col-span-6 sm:col-span-3" placeholder="John/Jane" label="First Name" :errors="errors.first_name" mark-as-required />
                    <text-input id="last-name" v-model="form.last_name" class="col-span-6 sm:col-span-3" placeholder="Doe" label="Last Name" :errors="errors.last_name" mark-as-required />
                    <text-input id="title" v-model="form.title" class="col-span-6 sm:col-span-3" placeholder="Client Success Champion" label="Title" :errors="errors.title" />
                    <text-input id="external-reference-id" v-model="form.external_reference_id" class="col-span-6 sm:col-span-3" placeholder="EXT-ABCD-12343" label="External Reference Id" :errors="errors.external_reference_id" />

                    <div class="col-span-6 sm:col-span-3">
                        <search-input @selected="selectClientCompany" label="Client Company" v-model="form.clientCompanyName" class="relative col-span-6 xs:col-span-4 md:col-span-2" :errors="errors.client_company_id" route="json.client-companies.index" :default-value="clientCompany" value-property="id" display-property="name" mark-as-required>
                            <template #default="obj">
                                <span class="block">{{ obj.result.name }}</span>
                            </template>
                        </search-input>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <Combobox as="div" v-model="form.location_ids" multiple>
                            <ComboboxLabel class="form-label" for="location_ids">Locations</ComboboxLabel>

                            <div class="relative">
                                <ComboboxInput id="location_ids" name="location_ids" class="form-input" @change="searchLocations($event)" :display-value="comboDisplayValue" autocomplete="off" />

                                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <!-- Heroicon name: solid/selector -->
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </ComboboxButton>

                                <div v-if="state === 'searching-locations'" class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <span class="mr-2">Searching...</span>
                                    <icon name="search" class="w-3 h-3 fill-current inline search-icon search-animation" />
                                </div>

                                <ComboboxOptions v-else-if="locations.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="location in locations" :key="location.id" :value="location.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                        {{ location.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </div>
                        </Combobox>
                    </div>

                    <toggle-switch-input id="is-decision-maker" v-model="form.is_decision_maker" label="Decision Maker" />
                    <textarea-input id="notes" v-model="form.notes" label="Notes" class="col-span-6 sm:col-span-3" :errors="errors.notes" autosize />
                </div>
            </fieldset>

            <fieldset class="space-y-8 sm:space-y-5">
                <legend class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Contact Info</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Email, phone, etc.</p>
                    </div>
                </legend>

                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <text-input type="email" id="email" v-model="form.email" class="sm:col-span-3" placeholder="johndoe@unknown.com" label="Email Address" :errors="errors.email" />
                    <text-input type="password" id="password" v-model="form.password" class="sm:col-span-3" label="Password" :errors="errors.password" />
                    <text-input type="text" id="primary-phone-number" v-model="form.primary_phone_number" class="sm:col-span-3" label="Primary Phone" :errors="errors.primary_phone_number" />
                    <text-input type="text" id="alternative-phone-number" v-model="form.alternative_phone_number" class="sm:col-span-3" label="Alternative Phone" :errors="errors.alternative_phone_number" />
                    <text-input type="text" id="mobile-phone-number" v-model="form.mobile_phone_number" class="sm:col-span-3" label="Mobile Phone" :errors="errors.mobile_phone_number" />
                    <text-input type="text" id="office-phone-number" v-model="form.office_phone_number" class="sm:col-span-3" label="Office Phone" :errors="errors.office_phone_number" />
                    <text-input type="text" id="fax-phone-number" v-model="form.fax_phone_number" class="sm:col-span-3" label="Fax Number" :errors="errors.fax_phone_number" />
                    <toggle-switch-input v-model="form.send_sms" :disabled="!form.mobile_phone_number" class="sm:col-span-3" label="Send SMS (Mobile Phone Required)" />
                </div>
            </fieldset>

            <div class="mt-8">
                <div class="flex justify-start">
                    <loading-button :loading="state === 'saving'" type="submit" class="btn btn-orange">Save</loading-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import { debounce } from 'lodash-es';
    import axios from 'axios';
    import { Head } from '@inertiajs/vue3';


    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            TextareaInput,
            TextInput,
            ToggleSwitchInput,
            SearchInput,
            Head,
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            client: {
                type: Object,
                required: true,
            },

            // Default client company if a client_company_id was passed via URL query string.
            clientCompany: {
                type: Object,
                required: false,
            },
        },

        data() {
            return {
                form: { ...this.client, is_decision_maker: false },
                locations: [],
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia
                        .post(this.$route('clients.store'), this.form, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            },

            selectClientCompany(clientCompany) {
                this.form.client_company_id = clientCompany?.id;
                this.form.clientCompanyName = clientCompany?.name;
            },

            comboDisplayValue(options) {
                if (Array.isArray(options)) {
                    if (options.length > 1) {
                        return `${options.length} locations selected`;
                    } else if (options.length === 1) {
                        return '1 location selected';
                    } else {
                        return 'No locations selected';
                    }
                }
            },

            searchLocations: debounce(function(event) {
                if (event.target.value && event.type !== 'change' && this.state === 'passive') {
                    this.state = 'searching-locations';

                    axios.get(this.$route('json.locations.index', {search: event.target.value.toLowerCase(), client_company_id: this.form.client_company_id, sort_by: 'locations.name', sort_direction: 'desc'})).then(response => {
                        this.locations = [
                            ...response.data?.data.map(location => ({id: location.id, name: location.name})) ?? [],
                            ...this.locations.filter(location => this.form.location_ids.includes(location.id)),
                        ].reduce((carry, location) => {
                            return carry.map(record => record.id).includes(location.id) ? carry : [location, ...carry];
                        }, []);
                    })
                    .finally(() => this.state = 'passive');
                }
            }, 800),
        },

	    watch: {
		    'form.mobile_phone_number': function (value) {
			    if (!value) {
				    this.form.send_sms = false;
			    }
		    }
	    }
    }
</script>

<style scoped>
    .search-icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }
    .search-animation {
        animation-name: search-animation;
        animation-timing-function: ease;
    }
    @keyframes search-animation {
        0%   { transform: translateY(0); }
        30%  { transform: translateY(-10px); }
        50%  { transform: translateY(0) translateX(5px); }
        100% { transform: translateY(0) translateX(0); }
    }
</style>