<template>
    <Head title="Client Contacts" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Client Contacts</span>
        </nav>
    </Teleport>

    <form @submit.prevent id="client-search-form" class="grid grid-cols-4 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-2 sm:col-span-1">
            <label for="search-clients" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-clients" id="search-clients" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                />

                <inertia-link :href="$route('clients.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="clients.data.length" class="col-span-2 sm:col-span-1 font-medium text-lg text-gray-700">Showing {{ clients.from }} - {{ clients.to }} out of {{ clients.total }} Records</div>

        <div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
            <inertia-link :href="$route('clients.create')" class="btn btn-orange mr-2 my-1">New</inertia-link>

            <loading-button :loading="state === 'saving'" class="btn btn-gray my-1" @click="exportData" :disabled="!clients.data.length">
                Export to CSV
            </loading-button>
        </div>
    </div>

    <div>
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <sortable-table-header field="first_name" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">First Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Last Name')" field="last_name" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Name</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Company')" field="client_companies.name" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Email')" field="email" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Title')" field="title" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Decision Maker')" field="is_decision_maker" :filters="filters" route-name="clients.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Decision Maker</sortable-table-header>
                        <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="client in clients.data" :key="client.id">
                        <td>
                            {{ client.first_name }}
                        </td>
                        <td v-show="form.selected_headers.includes('Last Name')">
                            {{ client.last_name }}
                        </td>
                        <td v-show="form.selected_headers.includes('Company')">
                          <inertia-link :href="$route('client-companies.show', [client.clientCompany.id])" class="link mr-2">
                            {{ client.clientCompany.name }}<span class="sr-only">, {{ client.clientCompany.name }}}</span>
                          </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Email')">
                            {{ client.email }}
                        </td>
                        <td v-show="form.selected_headers.includes('Title')">
                            {{ client.title }}
                        </td>
                        <td v-show="form.selected_headers.includes('Decision Maker')">
                            {{ client.is_decision_maker ? 'Yes' : 'No'}}
                        </td>
                        <td class="text-right">
                            <inertia-link :href="$route('clients.show', [client.id])" class="link mr-2">
                              View<span class="sr-only">, {{ client.name }}</span>
                            </inertia-link>
                            <inertia-link :href="$route('clients.edit', [client.id])" class="link" title="Edit This Client">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="clients.links" />
    </div>

    <div v-if="!clients.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="users" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Client Contacts Found</span>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import ColumnSelector from '@/Shared/ColumnSelector.vue';
    import SelectInput from "@/Shared/SelectInput.vue";

    export default {
        components: {
            Icon,
            LoadingButton,
            Pagination,
            SortableTableHeader,
            Head,
            ColumnSelector,
            SelectInput,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },
            
            clients: {
                type: Object,
                required: true
            },

            filters: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                form: {
                    search: this.filters.search,
                    sort_by: this.filters.sort_by,
                    sort_direction: this.filters.sort_direction,
                    selected_headers: this.filters.selected_headers,
                    per_page: this.filters.per_page,
                },
                headers: [],
                excludedHeaders: ['Actions', 'First Name'],
                state: 'passive',
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTableHeaders();

            if (this.form.selected_headers.length === 0) {
                this.form.selected_headers = this.headers;
            }

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.state = 'saving';

                this.$inertia.post(this.$route('csv.clients.index'), this.queryFilters, {
                    onFinish: () => { this.state = 'passive'; }
                });
            },

            getTableHeaders() {
                const table = this.$refs.table; // Get the table element
                const thElements = table.querySelectorAll('th'); // Get all the th elements
                const headers = Array.from(thElements)
                    .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
                    .map(th => th.textContent)
                    .sort((a, b) => a.localeCompare(b));
                this.headers = headers;
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route('clients.index'), this.queryFilters, {preserveState: true});
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = {...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            }
        }
    }
</script>