<template>
    <Head :title="`Client - ${client.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('clients.index')" class="breadcrumb-link">Client Contacts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ client.name }}</span>
        </nav>
    </Teleport>

    <div class="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl ">
        <div
            class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div class="flex items-center space-x-5">
                <div class="flex-shrink-0">
                    <icon name="user" class="h-14 w-14"/>
                </div>

                <div>
                    <h1 class="text-2xl font-bold text-gray-900">
                        {{ client.name }}
                        <span v-if="client.external_reference_id" class="text-lg font-semi-bold text-orange-300">
              {{ client.external_reference_id ?? 'EXT-123ABC' }}
            </span>
                    </h1>

                    <p class="text-sm font-medium text-gray-500">
                        <span class="text-gray-900"> Contact </span> added
                        <time :datetime="client.created_at">{{ $filters.format_date_time(client.created_at) }}</time>
                    </p>
                </div>
            </div>

            <div
                class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <a :href="$route('app.impersonate-client', client.id)" target="_blank" class="btn btn-blue">Impersonate</a>
                <inertia-link :href="$route('clients.edit', [client.id])" class="btn btn-gray">Edit</inertia-link>
                <button @click.prevent="deleteClient" class="btn btn-red">Delete</button>
            </div>
        </div>

        <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                <!-- Description list-->
                <section aria-labelledby="applicant-information-title">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                Contact Information
                            </h2>
                        </div>

                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Name</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ client.name }}</dd>
                                </div>

                                <div v-if="client.email" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Email</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ client.email }}</dd>
                                </div>

                                <div v-if="client.title" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Title</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ client.title }}</dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Company</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <inertia-link :href="$route('client-companies.show', [client.client_company_id])" class="link">
                                            {{ client.clientCompany.name }}
                                        </inertia-link>
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Is Decision Maker</dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ client.is_decision_maker ? 'Yes' : 'No' }}
                                    </dd>
                                </div>

                                <div v-if="client.primary_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Primary Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(client.primary_phone_number) }}</dd>
                                </div>

                                <div v-if="client.alternative_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Alternative Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(client.alternative_phone_number) }}</dd>
                                </div>

                                <div v-if="client.office_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Office Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(client.office_phone_number) }}</dd>
                                </div>

                                <div v-if="client.mobile_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Mobile Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(client.mobile_phone_number) }}</dd>
                                </div>

	                            <div v-if="client.mobile_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Send SMS</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ client.send_sms ? 'Yes' : 'No' }}</dd>
                                </div>

	                            <div v-if="client.smsSubscribedPhoneNumber" class="sm:col-span-1">
		                            <dt class="text-sm font-medium text-gray-500">SMS Opt-In?</dt>
		                            <dd class="mt-1 text-sm text-gray-900">{{ client.smsSubscribedPhoneNumber.status }}</dd>
	                            </div>

                                <div v-if="client.fax_phone_number" class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">Fax Phone Number</dt>
                                    <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(client.fax_phone_number) }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>
            </div>

            <div class="lg:col-start-3 lg:col-span-1 space-y-6">

                <section aria-labelledby="timeline-title">
                    <div class="my-2 mb-6">
                        <div class="col-span-2 sm:col-span-1">
                            <div class="rounded shadow-md px-4 py-5 sm:px-6 space-y-6">
                                <h2 id="sticky-notes-title" class="text-lg leading-6 font-medium text-gray-900">Notes</h2>

                                <p v-if="client.notes" class="leading-tight italic text-gray-500" v-html="client.notes" />
                                <p v-else class="leading-tight italic text-gray-500">There are no notes for this client.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="mt-8 max-w-3xl mx-auto  gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                These are the locations this contact is associated with.
                            </p>
                        </div>

                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="client.locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="location in client.locations.slice(0, 12)" :key="location.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>

                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('locations.edit', [location.id])" class="link">
                                                    Edit<span class="sr-only">, {{ location.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations are associated with this contact.</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import LocationCostBasisLineChart from '@/Shared/Reports/LocationCostBasisLineChart.vue';
    import LocationCostBasisTable from '@/Shared/Reports/LocationCostBasisTable.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            AddressPicker,
            LoadingButton,
            SelectInput,
            TextInput,
            ToggleSwitchInput,
            LocationCostBasisLineChart,
            LocationCostBasisTable,
            Head,
        },

        props: {
            client: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            deleteClient() {
                let confirmed = confirm('Are you sure you want to remove this contact?');
                if (confirmed) {
                    this.$inertia.delete(this.$route('clients.destroy', [this.client.id, {'client_company_id': this.client.clientCompany.id}]));
                }
            },
        }
    }
</script>